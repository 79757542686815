<template>
  <UiPopup
    v-model="modelValue"
    title="Delete rule"
    description="This rule will be deleted and will no longer be applicable to new leads. The deletion will not affect previously fixed leads."
    primary-button-text="Delete"
    secondary-button-text="Cancel"
    size="small"
    :loading="loading"
    @update:model-value="modelValue = false"
    @confirm="submit"
  />
</template>

<script setup lang="ts">
import { useUiStore } from '@/store/ui'

const emits = defineEmits(['update:modelValue', 'input'])
const uiStore = useUiStore()

const modelValue = defineModel<boolean>({ required: true })

type Props = {
  leadEnrichmentId: number
}
const props = defineProps<Props>()

const loading = ref(false)

const submit = async () => {
  try {
    loading.value = true
    await useDeleteLeadEnrichment(props.leadEnrichmentId)
    emits('input')
    modelValue.value = false
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
